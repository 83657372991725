<!--
 * @Author: Dennis Jiang
-->
<template>
  <div id="app">
    <!-- <van-grid direction="horizontal" :column-num="2">
      <van-grid-item
        :gutter="10"
        v-for="item in list"
        :key="item.id"
        :text="item.name"
      >
        <van-collapse v-model="item.id" accordion>
          <van-collapse-item :key="item.id" :title="item.name"
            ><template #title>
              <van-image :src="item.imageUrl" alt="123" />
            </template>
            {{ item.name + item.name + item.name }}
          </van-collapse-item>
        </van-collapse>
      </van-grid-item>
    </van-grid> -->
    <van-sticky>
      <van-nav-bar title="亿竑Mall" />
    </van-sticky>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item v-for="item in list" :key="item.id" :title="item.name"
        ><template #title>
          <van-card
            centered
            :title="item.name"
            :thumb="item.imageUrl"
          /> </template
        ><template #default>
          <van-cell-group>
            <van-cell title="净重" :value="item.netWeight" />
            <van-cell title="品类" :value="item.category" />
            <van-cell title="保质期" :value="item.expirationDate" />
            <van-cell title="保存方式" :value="item.storage" />
            <van-cell title="原料" :value="item.ingredient" />
            <van-cell
              title="详情"
              is-link
              url="weixin://dl/business/?t=myKzaRl2CCb"
            />
          </van-cell-group> </template
      ></van-collapse-item>
    </van-collapse>
    <div style="width:300px;margin:0 auto; padding:20px 0;">
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020302035086" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <img src="./assets/beian.png" style="float:left;"/>
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">闽公网安备 35020302035086号</p>
      </a>
    </div>
  </div>
</template>

<script>
import { ProductService } from "./services/api";
import _ from "lodash";
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      activeName: "1"
    };
  },
  mounted: function() {
    ProductService.getAllList()
      .then(res => {
        this.list = _.filter(res.result, product => {
          return product.status == 1 && product.category !== "礼品袋";
        }).reverse();
        this.total = res.total;
      })
      .catch(() => {});
  },
  name: "App"
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.van-card {
  text-align: center;
  background-color: white !important;
}
.van-card__title {
  line-height: 24px !important;
  font-size: 18px !important;
}
.van-cell {
  font-size: 16px !important;
}
.van-cell__value {
  color: #333 !important;
}
.van-collapse-item__content {
  background-color: #f2f3f5 !important;
}
.van-cell--clickable:active {
  background: white !important;
}
.van-nav-bar__content {
  height: 64px !important;
  background-color: #b4282d !important;
}
.van-nav-bar__title {
  color: white !important;
}
</style>
