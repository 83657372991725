/*
 * @Author: Dennis Jiang
 */
/*
 * @Author: Dennis Jiang
 */
"use strict";

import Vue from "vue";

let axios = Vue.axios;
export class ProductService {
  static getAllList(pageNum, pageSize, param) {
    return axios
      .get(`/product/list`, {
        params: {
          pageNum: pageNum || 1,
          pageSize: pageSize || 100,
          param: param
        }
      })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
  static update(body) {
    return axios
      .put(`/admin/product`, body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
  static insert(body) {
    return axios
      .post(`/admin/product`, body)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
  static getDetail(id) {
    return axios
      .get(`/product`, {
        params: {
          id: id
        }
      })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
  static getCategory() {
    return axios
      .get(`/product/category`)
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static deleteCategory(id) {
    return axios
      .delete(`/admin/product`, {
        params: {
          id: id
        }
      })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  static uploadImage(pic) {
    const formData = new FormData();
    formData.append("pic", pic);
    return axios
      .post(`/admin/product/uploadImage`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
